/* Register.css */

.register-container {
  max-width: 50%;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.register-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: black;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input {
  width: 90%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-group input:focus {
  border-color: #007bff;
  outline: none;
}

.register-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.register-button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  text-align: center;
  margin-bottom: 15px;
}

.success {
  color: green;
  text-align: center;
  margin-bottom: 15px;
}
