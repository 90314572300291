/* Home.css */

:root {
  --primary-color: #008080; /* Sea green */
  --secondary-color: #70a1ff; /* Soft blue */
  --accent-color: #ffa502; /* Warm accent color */
  --background-color: #f0f8ff; /* Light background */
  --text-color: #333; /* Dark text color */
}

body {
  font-family: 'Arial', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  /* background-color: #020024; */
}

.container {
  max-width: 85.25em; /* Adjust the maximum width as needed */
  margin: 0 auto;
  padding: 1.25em;
}

/* Hero Section */


.cta-button {
  background-color: var(--accent-color);
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
}

.cta-button:hover {
  background-color: #e58e26; /* Darker shade of the accent color */
}

/* Intro Section */
.intro {
  padding: 40px 20px;
  text-align: center;
}

.intro h2 {
  font-size: 28px;
  margin-bottom: 15px;
}

.intro p {
  font-size: 16px;
  margin: 0 auto;
  max-width: 90%;
}

/* Media Queries for Tablets and Desktops */
@media (min-width: 600px) {
  

  .cta-button {
    padding: 12px 20px;
    font-size: 18px;
  }

  .intro h2 {
    font-size: 32px;
  }

  .intro p {
    max-width: 70%;
  }

  .search input {
    max-width: 400px;
  }

  .article-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  

  .cta-button {
    padding: 15px 25px;
    font-size: 20px;
  }

  .intro h2 {
    font-size: 36px;
  }

  .intro p {
    max-width: 60%;
  }

  .search input {
    max-width: 500px;
  }

  .article-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.article-card.highlight {
  border: 2px solid var(--accent-color);
  background-color: var(--secondary-color);
  color: white;
  padding: 20px;
}

.intro-section {
  text-align: left;
  padding: 20px;
  /* background-color: #f9f9f9; */
  /* border-bottom: 1px solid #ddd; */
}

.intro-section h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.intro-section p {
  font-size: 1.0em;
  line-height: 1.6;
  margin-bottom: 20px;
}

.learn-more-link {
  display: inline-block;
  padding: 5px 10px;
  background-color: #0073e6;
  color: white;
  border-radius: 5px;
  text-decoration: none;
}

.learn-more-link:hover {
  background-color: #005bb5;
}

/* Mobile-Friendly Enhancements */
@media (max-width: 600px) {
  .article-card {
    width: 100%;
  }
  .article-image {
    width: 30%;
    height: auto;
  }
  .article-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .article-card {
    grid-template-columns: 1fr;
  }
}