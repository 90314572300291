/* Container for the login form */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  }
  
  /* Styling for the login form */
  .login-form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  /* Input fields */
  .login-form input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  /* Submit button */
  .login-form button {
    width: auto;
    padding: 0.75rem;
    /* background-color: #007bff; */
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin: 4px;
  }
  
  .login-form button:hover {
    background-color: #0056b3;
    color: white;
  }
  
  /* Message display */
  .login-form p {
    color: #000000bd; /* Error message color */
    font-weight: bold;
  }
  