/* Modal overlay that covers the entire screen */
.modal-overlay {
    position: fixed;
    top: 0;
    right: 0; /* Align to the right side */
    width: 100vw; /* Adjust the width of the overlay */
    height: 100vh; /* Adjust the height of the overlay */
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: flex-end; /* Align modal content to the right */
    align-items: flex-start; /* Align modal content to the top */
    z-index: 1000;
    padding: 10px; /* Add padding for space */
}

/* Modal content box */
.modal-content {
    background: white;
    padding: 0rem;
    border-radius: 8px;
    max-width: 500px;
    width: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 10px; /* Ensure there's margin inside the modal content area */
    padding: 6px;
    font-size: small;
}
/* Button to trigger modal */
.open-modal-btn {
    position: fixed; /* Change to fixed to keep the button positioned relative to the viewport */
    top: 10px;   /* 10px from the top of the screen */
    right: 10px; /* 10px from the right of the screen */
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    z-index: 2;
  }
  
  .open-modal-btn:hover {
    background-color: #0056b3;
  }
  
  /* Hamburger Menu */
  .hamburger-menu {
    position: fixed; /* Keep it fixed to viewport */
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 9999; /* Ensure it's high enough to overlay everything */
    pointer-events: auto; /* Ensure it remains clickable */
  }
  
  
  .hamburger-menu .bar {
    display: block;
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
    transition: 0.3s;
  }
  
  /* Navigation Menu for Mobile */
  .nav-menu {
    border: none;
  }
  

  
  .nav-menu ul {
    display: flex;
    flex-direction: column; /* Stack list items vertically */
    padding: 0;
    margin: 0;
    /* background: #282c34; */
    list-style-type: none;
  }
  .nav-menu ul li {
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    border-bottom: 1px solid black;;
    padding-bottom: 4px;
    font-size: s;
    padding: 2px;
  }
  
  .nav-menu ul li button {
    text-decoration: none;
    color: black;
    font-weight: 700;
    padding: 10px;
    display: block;
    /* background: #282c34; */
    justify-self: center;
    border: none;
  }

  .nav-menu ul li a {
    text-decoration: none;
    color: black;
    font-weight: 700;
    padding: 10px;
    display: block;
    /* background: #282c34; */
    justify-self: center;
  }
  
  .nav-menu ul li button:hover {
    background-color: black;
    color: white;
  }

  .nav-menu ul li a:hover {
    background-color: black;
    color: white;
  }
  
  
  /* Media Queries */
  @media (min-width: 769px) {
    .hamburger-menu {
      display: block;
    }
    .nav-menu {
      position: relative;
      background: transparent;
      padding: 0;
      text-align: center;
      transform: none; /* Ensure menu is not transformed off-screen */
    }
    .nav-menu ul {
      display: flex;
      justify-content: center;
    }
    .nav-menu ul li {
      margin: 0 15px;
    }
  }