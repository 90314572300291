.video-list {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  gap: 20px; /* Space between videos */
  padding: 10px; /* Padding for the container */
  /* border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray; */
}

.video {
  flex: 0 0 auto; /* Prevent flex item from growing/shrinking */
  width: 300px; /* Fixed width for each video */
}

iframe {
  width: 100%;
  height: auto;
}

.youtube-link {
  margin-top: 20px;
  text-align: center;
}

.youtube-link a {
  color: #2cd8d5; /* Change to your preferred color */
  text-decoration: underline;
  font-size: small;
}

.youtube-button {
  width: 40px; /* Set width of the icon */
  height: 40px; /* Set height of the icon */
  display: inline-block; /* Ensure the icon is inline with text */
  background-size: contain; /* Scale the background image to fit the container */
  vertical-align: middle; /* Align with text */
  /* background-color: #6986bd; */
  cursor: pointer;
  padding-bottom: 14px;
  border: none;
  border-radius: 5px;
  
}
