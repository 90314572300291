/* Header.css */

/* Top Ribbon */
.top-ribbon {
  position: relative;
  background-image: url("../images/header1.png");/*linear-gradient(to bottom, #d7edef 0%, #e7f2fa 100%);*/
  /* background: linear-gradient(to right, #d7edef, #e7f2fa); Subtle aqua gradient */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  /* padding: 10px; */
  border-radius: 10px;
  color: black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif; /* Modern font choice */
  margin-bottom: 4px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  padding: 4px;

}

/* .top-ribbon::before {
  content: "easyfishkeeping.in";
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 12px;
  color: #999;
  opacity: 0.5;
  font-weight: normal;
  pointer-events: none;
  z-index: 10;
} */

/* Top Ribbon Headings */
.top-ribbon h1,
.top-ribbon h2,
.top-ribbon span {
  margin: 0;
  padding: 0;
}

.top-ribbon h1 {
  font-size: 24px;
  margin-bottom: 5px;
}

.top-ribbon h2 {
  font-size: 32px;
  color: black;
  color: black; /* Text color */
  text-shadow: 
    -1px -1px 0px white,
    1px -1px 0px white,
    -1px 1px 0px white,
    1px 1px 0px white; /* Create a white border effect */
}

.top-ribbon span {
  font-size: 16px;
  margin-bottom: 15px;
  font-style: italic;
  color: black; /* Text color */
  text-shadow: 
    -1px -1px 0px white,
    1px -1px 0px white,
    -1px 1px 0px white,
    1px 1px 0px white; /* Create a white border effect */

}

/* Search Input */
.search {
  margin-top: 20px;
}

.search input {
  width: 80%;
  max-width: 300px;
  padding: 12px 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
  background-color: #fff;
}

.search input:focus {
  outline: none;
  border-color: #ccc;
}

/* Dropdown suggestions menu */
.autocomplete-menu {
  list-style-type: none;
  padding: 0;
  margin: 2px auto;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  z-index: 3;
  width: 80%;
  max-width: 300px;
}

.autocomplete-menu li {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  color: #333;
}

.autocomplete-menu li:last-of-type {
  border-bottom: none;
}

.autocomplete-menu li:hover {
  background-color: #f5f5f5;
}

.autocomplete-menu li.selected {
  background-color: #e0f2ff;
  font-weight: bold;
}

/* Clear button styles */
.clear-button {
  width: 30px;
  height: 30px;
  display: inline-block;
  background: url("../images/clear.png") no-repeat center center;
  background-size: contain;
  vertical-align: middle;
  background-color: #fff;
  cursor: pointer;
  padding: 8px;
  margin-left: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.clear-button:hover {
  background-color: #e0e0e0;
}

.App-logo{
  width: 10%;
  height: auto;
  float: left;
  /* object-fit: cover; */
  margin: 4px;
  border-radius: 30px;
  cursor: pointer;
  /* transition: background-color 0.3s ease, transform 0.3s ease; */
  /* box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1); */
  position: absolute;
  display: flex;
  vertical-align: top;
}

/* Ribbon Styles */
.ribbon {
  position: relative;
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap on small screens */
  margin: auto;
  width: 99%;
  justify-content: center;
  /* background: #2CD8D5;
  background: linear-gradient(
    to right,
    #2CD8D5, #6B8DD6, #8E37D7
  ); */
  border-radius: 10px;
  padding: 4px;
  margin-bottom: 4px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1); */

  /* Centering at the top of the screen */
  /* top: 5; */
  /* right: 50%; */
  /* transform: translateX(-50%); */
}


.ribbon button {
  background: #536976;
  background: linear-gradient(
    to right,
    #536976,#292E49
  );
  color: white;
  border: none;
  border-radius: 25px;
  padding: 5px 10px;
  margin: 3px; /* Adjust margin for better spacing */
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

.ribbon button:hover {
  background-color: #88cbb0;
  transform: translateY(-3px);
}

.ribbon button:active {
  background-color: #76b29b;
  transform: translateY(0);
}

.ribbon button:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(136, 203, 176, 0.5);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .top-ribbon {
    padding: 15px; /* Adjust padding */
  }

  .top-ribbon h1 {
    font-size: 20px;
  }

  .top-ribbon h2 {
    font-size: 24px;
  }

  .top-ribbon span {
    font-size: 8px;
  }

  .search input {
    width: 90%;
    max-width: 100%;
  }

  .autocomplete-menu {
    width: 90%;
    max-width: 100%;
  }
  .ribbon button {
    font-size: 0.75rem;
  }
}

@media (max-width: 599px) {
  .top-ribbon {
    padding: 10px;
    height: auto;
  }

  .top-ribbon h1 {
    font-size: 18px;
  }

  .top-ribbon h2 {
    font-size: 20px;
  }

  .top-ribbon span {
    font-size: 12px;
    word-wrap: break-word;
  }

  .search input {
    width: 100%;
  }

  .autocomplete-menu {
    width: 100%;
  }

  .ribbon {
    width: 87dvw;
  }

  .ribbon button {
    font-size: 0.75rem;
  }

  .search input {
    font-size: 0.75rem;
    max-width: 60dvw;
  }
  .App-logo{
    top:20%;
  }
}