/* Body.css */

.body {
  padding: 0;
  /* background-color: #f5f5f5; Light gray background */
  color: #333; /* Dark gray text */
  font-family: 'Roboto', sans-serif;
  width: 100%;
  /* border: 1px solid lightgray; */
}

.articles {
  padding: 0;
}

.article-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;
  /* max-height: 50dvh;
    overflow-y: auto;
    border-bottom: 1px solid lightgray;
    border-top: 1px solid lightgray;
    padding: 4px; */
  
}

.article-card {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* width: 350px; */
  height: auto; /* Adjust height as needed */
  overflow-y: auto;
  font-size: 1em;
  padding-bottom: 8px;
  margin: auto;
}

.article-card:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.article-content {
  padding: 20px;
}

.article-image {
  width: 40%;
  height: auto;
  float: right;
  object-fit: cover;
  margin: 4px;
}

.article-image-large {
  width: 50%;
  height: auto;
  object-fit: cover;
  margin: 4px;
  margin-left: 22%;
}

.article-detail {
  padding: 20px;
  margin-bottom: 4px;
  background-color: #fff;
}

.article-detail p {
  line-height: 1.6;
  margin-bottom: 4px;
}

.info{
  font-style: italic;
  text-decoration: underline;
}

/* Back button styles */
.back-button {
   width: 30px; /* Set width of the icon */
   height: 30px; /* Set height of the icon */
   display: inline-block; /* Ensure the icon is inline with text */
   background: url('../images/back.png') no-repeat center center; /* Replace 'icon.svg' with your icon file */
   background-size: contain; /* Scale the background image to fit the container */
   vertical-align: middle; /* Align with text */
   /* background-color: #6986bd; */
   cursor: pointer;
   padding-bottom: 14px;
   border: none;
   border-radius: 5px;
   }
   
   .back-button:hover {
   background-color: aquamarine; /* Slightly lighter gray for hover */
   }
  
   .share-button-heading {
    width: 20px; /* Set width of the icon */
    height: 20px; /* Set height of the icon */
    /* display: inline-block; Ensure the icon is inline with text */
    background: url('../images/share.png') no-repeat center center; /* Replace 'icon.svg' with your icon file */
    background-size: contain; /* Scale the background image to fit the container */
    /* vertical-align: middle; Align with text */
    /* background-color: #6986bd; */
    cursor: pointer;
    /* padding-bottom: 14px; */
    border: none;
    border-radius: 5px;
    margin-left: 4px;;
    float: right;
    }

   /* Share button styles */
  .share-button {
   width: 30px; /* Set width of the icon */
   height: 30px; /* Set height of the icon */
   display: inline-block; /* Ensure the icon is inline with text */
   background: url('../images/share.png') no-repeat center center; /* Replace 'icon.svg' with your icon file */
   background-size: contain; /* Scale the background image to fit the container */
   vertical-align: middle; 
   /* background-color: #6986bd; */
   cursor: pointer;
   /* padding-bottom: 14px; */
   border: none;
   border-radius: 5px;
   }
   
   .share-button:hover {
   background-color: aquamarine; /* Darker blue for hover */
   }

   .share-button-heading:hover {
    background-color: aquamarine; /* Darker blue for hover */
    }
.button-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.primary {
  padding: 10px 15px;
  background-color: white;
  color: black;
  /* border: none; */
  border-radius: 100px;
  cursor: pointer;
  border: 1px solid #333;
}

.primary:hover {
  background-color: aquamarine;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .article-grid {
    grid-template-columns: 1fr;
    align-items: center;
  }

  .article-image {
    width: 30%;
    clip-path: none;
  }

  .article-detail p {
    padding: 10px;
    max-width: 95%;
    margin-bottom: 4px;
  }

  .back-button,
  .share-button {
    width: 24px;
    height: 24px;
  }
}