/* ArticleDetail.css */

.article-detail {
  padding: 1.25em; /* 20px */
  max-width: 83em; /* 800px */
  margin: 0 auto;
  border-radius: 0.5em; /* 8px */
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  /* margin-top: 1em; */
}

.article-detail h2 {
  font-size: 2em;
  margin-bottom: 1.25em; /* 20px */
}

.article-detail .article-details {
  font-size: 1em;
  line-height: 1.6;
  color: #555;
}


.article-detail p {
  margin: 0.625em 0; /* 10px 0 */
}

.article-detail a {
  color: #007bff;
  text-decoration: none;
}

.article-detail a:hover {
  text-decoration: underline;
}

.home-button {
  background-color: #4CAF50; /* Green background */
  color: white;              /* White text */
  padding: 10px 20px;        /* Padding */
  border: none;              /* Remove border */
  cursor: pointer;           /* Pointer cursor */
  font-size: 16px;           /* Font size */
  border-radius: 5px;        /* Rounded corners */
  margin-top: 4px;;
}

.home-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.ad-container {
  margin-top: 20px;
  padding: 10px;
  border-top: 1px solid #ccc;
  text-align: center;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .article-detail {
    padding: 1em; /* Reduce padding on mobile */
    max-width: 96%; /* Increase width on mobile */
  }
}
