.about {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    color: black;
  }
  
  .about h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .about p {
    font-size: 1.2em;
    line-height: 1.6;
  }
  