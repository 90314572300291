.contact {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    color: black;

  }
  
  .contact h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .contact form {
    display: flex;
    flex-direction: column;
  }
  
  .contact label {
    margin-top: 10px;
  }
  
  .contact input, .contact textarea {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 1em;
  }
  
  .contact button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: #007bff;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact button:hover {
    background-color: #0056b3;
  }
  