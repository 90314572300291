/* Profile.css */


  
  /* Add some spacing and centering */
  .profile-info {
    max-width: 72vw;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }

    
  .profile-info h1 {
    text-align: center;
    margin-bottom: 20px;
    color: black;
  }
  
  .profile-info h1 button{
    float: left;
  }

  .profile-info p {
    font-size: 1rem;
    margin: 10px 0;
  }
  
  
  